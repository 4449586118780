// $primary: #13348D;
// $primary-hover: #2f478a;
$primary: #bb301b;
$primary-hover: #8d2313;
$secondary: #8b5047;
$secondary-hover: #a53f2b;

$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;

.primary-button {
	background-color: $primary !important;
	border-color: $primary !important;
	// hover
	&:hover {
		background-color: $primary-hover !important;
		border-color: $primary-hover !important;
	}
}

.primary-button-outline {
	background-color: transparent !important;
	border-color: $primary !important;
	color: $primary !important;
	// hover
	&:hover {
		background-color: $primary !important;
		border-color: $primary !important;
		color: white !important;
	}
}

.success-button-outline {
	background-color: transparent !important;
	border-color: $success !important;
	color: $success !important;
	// hover
	&:hover {
		background-color: $success !important;
		border-color: $success !important;
		color: white !important;
	}
}

.danger-button-outline {
	background-color: transparent !important;
	border-color: $danger !important;
	color: $danger !important;
	// hover
	&:hover {
		background-color: $danger !important;
		border-color: $danger !important;
		color: white !important;
	}
}

.themed-link {
	color: $primary;
	text-decoration: none;
	// hover
	&:hover {
		color: $primary-hover;
		text-decoration: underline;
	}
	&:visited {
		color: $secondary;
	}
}

.primary-navbar {
	background-color: $primary !important;
}

.primary-spinner {
	border-color: $primary !important;
	border-top-color: transparent !important;
}

.primary-progress-bar .progress-bar {
	background-color: $primary !important;
}

.primary-progress-bar .progress-bar-animated {
	background-color: $primary !important;
}

.active-nav-link {
	background-color: $primary !important;
	color: white !important;
}

.inactive-nav-link {
	// background-color: $primary-hover !important;
	color: $primary !important;
}

// Override for Logout navbar link
.inactive-navbar-link {
	color: white !important;
	opacity: 0.5;
	&:hover {
		color: white !important;
		opacity: 0.75;
	}
}
